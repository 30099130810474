import { DateTime } from 'luxon';

export const fromISO = (datetime: string) => {
  // If no zone set, default zone is system's local zone.
  // https://github.com/moment/luxon/blob/master/docs/zones.md#system-zone-by-default
  return DateTime.fromISO(datetime, { zone: 'UTC' });
};

export const now = () => {
  return DateTime.now();
};

export const latest = (...dateTimes: Parameters<typeof DateTime.max>) => {
  return DateTime.max(...dateTimes);
};

export const formatToDate = (
  dateTime: string | null,
  { zone = 'Asia/Tokyo', format = 'yyyy年MM月dd日' } = {}
): string => {
  if (!dateTime) {
    return '';
  }
  const date = fromISO(dateTime).setZone(zone).toFormat(format);
  return date == 'Invalid DateTime' ? '' : date;
};

export const formatToDateTime = (
  dateTime: string,
  { zone = 'Asia/Tokyo', format = 'yyyy年MM月dd日 HH時mm分' } = {}
): string => {
  return fromISO(dateTime).setZone(zone).toFormat(format);
};
